<template>
  <v-container class="mt-0 pt-0 accountsData" style="min-height: 100%">
    <v-row class="pr-2">
      <v-col cols="6">
        <v-data-table
          dense
          locale="ru"
          item-key="id"
          single-line
          sort-by="last_active_time"
          :custom-filter="tableSearch"
          :sort-desc="true"
          :search="search"
          :single-select="true"
          :headers="usersHeaders"
          :items="$store.getters.getUsers"
          :items-per-page="itemsPerPage"
          class="elevation-1 accountsTable"
        >
          <template v-slot:top>
            <div class="d-flex">
              <div style="flex: 1">
                <v-text-field
                  v-model="search"
                  clearable
                  label="Поиск"
                  class="mx-4"
                  :append-icon="search&&search.length?'mdi-plus':'mdi-magnify'"
                  @click:append="addUser"
                ></v-text-field>
              </div>
              <div class="d-flex align-center">
              <v-switch
                v-model="kickActive"
                color="#FF5252"
              ><template #prepend>
                  <div class="kickLabel">
                      <v-label>Кик</v-label>
                  </div>
                </template>
              </v-switch>
              </div>
           </div>
          </template>
          <template v-slot:item="{ item }">
            <tr
              :class="selectedUser && selectedUser.id == item.id ? 'amber lighten-4' : ''"
              @click="openUserCard(item)"
            >
              <td>{{ item.id }}</td>
              <td style="min-width: 180px">
                {{ item.name }}<br>
                <small>{{ item.login }}</small>
              </td>
              <td>
                <v-chip
                  class="mr-2 mt-2 pl-2 pr-2"
                  v-for="(tag,i) of item.tags"
                  :key="i"
                  :color="`${tag.color}`"
                  label
                  small
                >
                  {{ tag.text }}
                </v-chip>
              </td>
              <td>
                <span :style="`color: ${getSessionColor(item)}`">{{drawLastActive(item.last_active_time)}}</span>
                <br>
                <span :style="getLkVersionStyles(item)">{{item.lk_version_txt}}</span>
              </td>
              <td v-if="kickActive">
                <v-menu offset-y open-on-hover v-if="item.session_expire_time && item.session_expire_time>now">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      class="pl-0 pr-0 ml-1"
                      @click="kickUser(item.id)"
                    ><v-icon small color="red">mdi-karate</v-icon></v-btn>
                  </template>
                  <v-card>
                    <v-card-text>Разорвать соединение пользователя</v-card-text>
                  </v-card>
                </v-menu>
              </td>
            </tr></template
          ></v-data-table
        >
      </v-col>
      <v-col cols="6" class="d-flex">
        <account-card :selectedUser="selectedUser" />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarUserUpdate"
      :timeout="2000"
      right
      color="success"
      class="mb-16"
    >
      Успешно
    </v-snackbar>
  </v-container>
</template>

<style scoped>
.accountsData {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,.87);
}
.accountsTable {
  position: sticky; top: 65px; z-index: 1;
}
.accountsTable td {
  padding: 14px!important;
  line-height: 20px;
}
.accountsTable small {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: rgba(0,0,0,.58);
}
.kickLabel {
  width: 32px; 
  padding-top:2px; 
  margin-left: 16px;
}
.kickLabel>label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,.87);
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Utils from "../services/utils";
import AccountCard from "@/components/AdminAccountCard.vue";

export default {
  components: {
    AccountCard,
  },
  data: () => ({
    rolesItems: [
      {id: "", name: "Пользователь"},
      {id: "administrator", name: "Администратор"},
    ],
    kickActive: false, // показывать кнопку кика
    userActive: false,
    selectedUser: null,
    search: "",
    filter: {},
    sortDesc: true,
    page: 1,
    itemsPerPage: 10,
    currentLkVerison: null,
  }),
  computed: {
    snackbarUserUpdate() {
      return this.$store.getters.getUserSnackbar;
    },
    usersHeaders() {
      const headers = [
        { text: "Id", value: "id", width: 80 },
        { text: "Имя", value: "name" },
        { text: "Теги", value: "tags" },
        { text: "Активность", value: "last_active_time", width: 180 },
      ];
      if (this.kickActive) {
        headers.push({ text: "", value: "id", width: 30 });
      }
      return headers;
    },
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    numberOfPages() {
      return Math.ceil(this.$store.getters.getUsers.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    ...mapActions(["GetUsers", "SaveUser", "AddUser", "KickUser", "GetUserAllPrivs"]),
    tableSearch(value, search, item) {
      //console.log(value, search, item);
      if (item.login.toLowerCase().includes(search.toLowerCase())) return true;
      if (item.name.toLowerCase().includes(search.toLowerCase())) return true;
      if (item.id.includes(search)) return true;
      for (const tag of item.tags) {
        if (tag.text.toLowerCase().includes(search.toLowerCase())) return true;
      }
      return false;
    },
    async openUserCard(user) {
      this.selectedUser = user;
      console.log(user.id)
      await this.GetUserAllPrivs(user.id);
    },
    getLkVersionStyles(user) {
      let color = "rgba(0,0,0,.38)";
      if (user.lk_version !== null &&
        this.$store.getters.getCurrentLkVersion !== null &&
        user.lk_version < this.$store.getters.getCurrentLkVersion
      ) {
        color = "red";
      }
      return `color: ${color};`;
    },
    getSessionColor(user) {
      if (user.session_expire_time && user.session_expire_time>this.now) return "green";
      return "orange";
    },
    drawLastActive(time) {
      if (!time) {
        return "Никогда";
      }
      const diffDays = moment(this.now, "YYYY-MM-DD HH:mm:ss").diff(moment(time, "YYYY-MM-DD HH:mm:ss"), "days");
      const diffHours = moment(this.now, "YYYY-MM-DD HH:mm:ss").diff(moment(time, "YYYY-MM-DD HH:mm:ss"), "hours");
      //console.log(diffDays, diffHours);

      let days = 1;
      if (diffDays == 0) {
        //console.log(diffHours)
        if (diffHours < 1) return "Только что";
        if (this.now.substring(0, 10) == time.substring(0, 10)) return "Сегодня";
      } else {
        days = diffDays;
      }
      time = Utils.pluralForm(days, "день", "дня", "дней") + " назад";
      return time;
    },
    drawRole(roleId) {
      const roleItem = this.rolesItems.find(item=>item.id==roleId)
      if (!roleItem) return "";
      return roleItem.name;
    },
    generatePassword() {
      const length = 8;
      const charset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let passwd = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        passwd += charset.charAt(Math.floor(Math.random() * n));
      }
      return passwd;
    },
    newPassword(userId) {
      this.$store.commit("clearUsersEdits");
      this.$store.commit("updateUser", {userId, "password": this.generatePassword()});
    },
    async addUser() {
      if (this.search.length) {
        console.log(`Add: ${this.search}`);
        await this.AddUser({ name: this.search });
      }
    },
    async kickUser(userId) {
      await this.KickUser({ id: userId });
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
    editField(userId, field) {
      this.$store.commit("clearUsersEdits");
      this.$store.commit("updateUser", {userId, edit: field});
      if (field == "password") {
        //console.log("ops")
        this.$store.commit("updateUser", {userId, password: ""});
      }
    },
    async updateDates(userId) {
      console.log(userId);
    },
    async updateField(userId, field, value) {
      console.log(userId, field, value);
      this.$store.commit("updateUser", {userId, [field]: value});
      this.editField(userId, null); //reset editing
    },
    async updateUser(userId, field, value) {
      if (field=="maxActive") {
        value = moment(value).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (field=="minActive") {
        value = moment(value).format("YYYY-MM-DD") + " 00:00:00";
      }
      this.$store.commit("updateUser", {userId, [field]: value});
      await this.SaveUser({ id: userId, [field]: value});
      this.editField(userId, null); //reset editing
    },
    async toggelUserActive(userId) {
      const user = this.$store.getters.getUsers.find(
        (item) => item.id == userId
      );
      const active = this.$store.getters.getUser(userId).active;
      await this.SaveUser({ id: user.id, active });
      //await this.GetUsers();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  watch: {
    selectedUser: function (value) {
      console.log(value.id);
    },
  },
};
</script>
